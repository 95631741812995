.list {
    padding: 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: rgba(248, 249, 250, 1);

    &:hover {
        cursor: pointer;
    }
}
