.active,
.inactive:hover {
    padding: 8px !important;
    border-radius: 12px !important;
    background-color: rgba(17, 25, 39, 0.04) !important;
    color: rgba(108, 115, 127, 1) !important;
}

.paper {
    max-width: 252px !important;
    width: 100%;
    margin-top: 9px;
}
