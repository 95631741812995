.dialog {
    border-radius: 20px !important;

    .dialogTitle {
        font-weight: 700;
        color: rgba(26, 28, 35, 1);
    }

    .dialogSubTitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba(97, 100, 117, 1);
    }
}
